.project-page-container {
    height: 100vh;
    width: 100vw;
    background-color: black !important;
    overflow: hidden;
    color: white;
    font-family: 'Montserrat', sans-serif;
}

.star-background {
    z-index: 1;
    position: absolute;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
}

.project-container {
    position: absolute;
    z-index: 100;
    overflow: hidden;
    background-color: black !important;
    height: 100vh;
    width: 100vw;
}

.project-col-container {
    /*height: 100vh;*/
    overflow: hidden;
}

.scroll-project {
    overflow: auto;
    height: 100vh;
}

.project-row {
    height: 100vh;
}


.left-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.contact-col {
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-left {

    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;

    border-right: 20px solid white;

}

.div-left {
    padding: 10px;
}

.canvas-elem {
    border: 1px solid white;
}


