.landing-page-container {
    height: 100vh;
    width: 100vw;
    background-color: black;
    overflow: hidden;
    color: white;
}

.main-container {
    position: absolute;
    z-index: 100;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
}


.star-background {
    z-index: 1;
    position: absolute;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
}


@media screen and (max-width: 600px) {
    div.text-col {
        font-size: 15px;
        padding: 30px;
    }
    div.left-col {
        flex-direction: column;
    }
    div.right-col {
        flex-direction: column-reverse;
    }
}

@media screen and (min-width: 601px) {
    div.text-col {
        font-size: 30px;
        padding: 10px;
    }
}

.top-row {
    height: 10vh;
}

.middle-row {
    height: 80vh;
}

.bottom-row {
    height: 10vh;
}

.left-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.center-col {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}


.right-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.top-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bottom-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.text-col {
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
}


.arrow-up {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-bottom: 20px solid white;
}

.div-up {
    padding: 2px;
}

.arrow-down {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-top: 20px solid white;
}


.div-down {
    padding: 5px;
}


.arrow-right {
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;

    border-left: 20px solid white;
}


.div-right {
    padding: 10px;
}


.arrow-left {

    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;

    border-right: 20px solid white;

}

.div-left {
    padding: 3px;
}


