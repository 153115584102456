@media only screen and (max-width: 600px) {
    div.sun-col {
        display: none;
    }
    div.empty-contact-col {
        display: none;
    }
}


.contact-page-container {
    height: 100vh;
    width: 100vw;
    background-color: black;
    overflow: hidden;
    color: white;
    font-family: 'Montserrat', sans-serif;
}

.star-background {
    z-index: 1;
    position: absolute;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
}

.contact-container {
    position: absolute;
    z-index: 100;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
}

.contact-col-container {

}

.contact-row {
    height: 100vh;
}

.right-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.contact-col {
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow-right {
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;

    border-left: 20px solid white;
}

.div-right {
    padding: 10px;
}

.canvas-elem {
    border: 1px solid white;
}

.first-row {
    height: 33vh;

}

.second-row {
    height: 33vh;
}

.third-row {
    height: 33vh;
}

.github-contact-col {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.linkedin-contact-col {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.facebook-contact-col {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.youtube-contact-col {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.email-contact-col {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.cv-contact-col {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.sun-col {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}


a { color: inherit; }

.cv-image{
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}

.icon-button {
    color: white;
}

.linkedin-button {
    display: inline-block;
    text-decoration: none;
    color: #FFF;
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    background-image: -webkit-linear-gradient(45deg, #709dff 0%, #91fdb7 100%);
    background-image: linear-gradient(45deg, #709dff 0%, #91fdb7 100%);
    transition: .4s;
}

.linkedin-button:hover{
    -webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
}

.github-button {
    display: inline-block;
    text-decoration: none;
    color: #FFF;
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    background-image: -webkit-linear-gradient(45deg, #fed6e3 0%, #ffaaaa 100%);
    background-image: linear-gradient(45deg, #fed6e3 0%, #ffaaaa 100%);
    transition: .4s;
}

.github-button:hover{
    -webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
}

.facebook-button {
    display: inline-block;
    text-decoration: none;
    color: #FFF;
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    background-image: -webkit-linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%);
    background-image: linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%);
    transition: .4s;
}

.facebook-button:hover{
    -webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
}

.youtube-button {
    display: inline-block;
    text-decoration: none;
    color: #FFF;
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    background-image: -webkit-linear-gradient(45deg, #c79081  0%, #dfa579 100%);
    background-image: linear-gradient(45deg, #c79081  0%, #dfa579 100%);
    transition: .4s;
}

.youtube-button:hover{
    -webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
}

.email-button {
    display: inline-block;
    text-decoration: none;
    color: #FFF;
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    background-image: -webkit-linear-gradient(45deg, #29323c  0%, #485563 100%);
    background-image: linear-gradient(45deg, #29323c  0%, #485563 100%);
    transition: .4s;
}

.email-button:hover{
    -webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
}

.cv-button {
    display: inline-block;
    text-decoration: none;
    color: #FFF;
    width: 120px;
    height: 120px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    background-image: -webkit-linear-gradient(45deg, #1e3c72  0%, #2a5298 100%);
    background-image: linear-gradient(45deg, #1e3c72  0%, #2a5298 100%);
    transition: .4s;
}

.cv-button:hover{
    -webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
}

.sun {
    display: inline-block;
    text-decoration: none;
    color: #FB7209;
    width: 140px;
    height: 140px;
    line-height: 140px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    border:0 !important;
    background-clip: padding-box;
    background-position: -28px -103px;
    background-size: 175%;
    background:radial-gradient(ellipse at center, #ffd000 1%,#f9b700 39%,#f9b700 39%,#e06317 100%);
    box-shadow: 0 0 10px 2px rgba(255, 107, 0, 0.4), 0 0 22px 11px rgba(255, 203, 0, 0.13);
}


