.about-page-container {
    height: 100vh;
    width: 100vw;
    background-color: black;
    overflow: hidden;
    color: white;
}



.about-container {
 

}

.about-window {
    border: 2px inset #334d50;
    border-radius: 10px; 
    box-shadow:
    0 0 0 4px rgb(136, 147, 164),
    0 0 0 6px rgb(15, 14, 16),
    0 0 0 8px rgb(80, 87, 100),
    0 0 0 10px rgb(15, 14, 16);

}

.left-about-col {

}

.right-about-col { 

}


.desc-row {
    height: 10vh;
}

.about-row {
    height: 80vh;
}

.landing-row {
    height: 10vh;

}


.landing-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.about-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.desc-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.arrow-down {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-top: 20px solid #ffffff;
}


.div-down {
    padding: 0px;
}

