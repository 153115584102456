.game-container {
    height: 100vh;
    width: 100vw;
    background-color: black;
    overflow: hidden;
    color: white;
}
.game-container {

}

.landing-row {
    height: 10vh;
}

.game-row {
    height: 90vh;
}
.landing-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.game-col {
    display: flex;
    align-items: center;
    justify-content: center;
}


.arrow-up {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-bottom: 20px solid white;
}

.div-up {
    padding: 2px;
}
