// n is number of stars required
@function multiple-box-shadow ($n) {
  $value: '#{random(2000)}px #{random(2000)}px #007bb8';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #007bb8'; }

  @return unquote($value); }

@function multiple-box-shadow-white ($n) {
  $value: '#{random(2000)}px #{random(2000)}px #FFF';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF'; }

  @return unquote($value); }

@function multiple-box-shadow-orange ($n) {
  $value: '#{random(2000)}px #{random(2000)}px #FFBF00';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFBF00'; }

  @return unquote($value); }

$shadows-small:  multiple-box-shadow-white(700);
$shadows-medium: multiple-box-shadow-orange(200);
$shadows-big:    multiple-box-shadow(100);

$randomNumber: random(5);

.project-page-container {
  height: 100%;
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
  overflow: hidden; }

.stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  animation			: animStar 50s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small; } }

.stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;
  animation			: animStar 100s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium; } }

.stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;
  animation			: animStar 150s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big; } }



@keyframes animStar {
  from {
    transform: translateY(-2000px); }
  to {
    transform: translateY(0px); } }

