/*@media only screen and (max-width: 600px) {*/
/*    div.div-left {*/
/*        padding: 2px;*/
/*    }*/
/*}*/

.project-page-container {

}

.project-container {
    /*position: absolute;*/
    /*z-index: 100;*/
    /*height: 100vh;*/
    /*width: 100vw;*/
    height: 100vh;
    width: 100vw;
    background-color: black;
    color: white;
}

.project-row {

}

.left-col-project {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: fixed;
    background-color: black;
    color: white;
    height: 100vh;

}

.right-col-project {
    height: 100vh;
}

.project-col {
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /*flex-direction: column;*/
    background-color: black;
    height: 100vh;
    overflow: auto;
}

.arrow-left {

    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;

    border-right: 20px solid white;

}

.div-left {
    padding: 10px;
}

.project-page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 100px;
}

.project-title {
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: "Lucida Console", Monaco, monospace;
    font-size: 40px;
    letter-spacing: 0px;
    word-spacing: 2px;
    color: #F1F1F1;
    font-weight: 400;
    text-decoration: rgb(68, 68, 68);
    font-variant: small-caps;
    text-transform: capitalize;
}
.cv-box {
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: "Lucida Console", Monaco, monospace;
    font-size: 21px;
    letter-spacing: 0px;
    word-spacing: 2px;
    color: #F1F1F1;
    font-weight: 400;
    text-decoration: rgb(68, 68, 68);
    font-style: italic;
    font-variant: normal;
    text-transform: none;
}

.cv-box-title {
    float: left;
}

.cv-box-date {
    float: right;
}

.experience-block {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: "Lucida Console", Monaco, monospace;
    font-size: 21px;
    letter-spacing: 0px;
    word-spacing: 2px;
    color: #F1F1F1;
    font-weight: 400;
    text-decoration: rgb(68, 68, 68);
    font-style: normal;
    font-variant: normal;
    text-transform: none;

}
